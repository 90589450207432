// reducers/index.js
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

 import admin from "./admin";

const persistConfig = {
  key: "rpa",
  // localStorage에 저장.
  storage,
  // reducer 중에 whitelist에 포함되어 있는 reducer만 localstorage에 저장.
  whitelist: ["admin"]
  // blacklist 사용 시 제외
};

const rootReducer = combineReducers({
  admin
});

export default persistReducer(persistConfig, rootReducer);