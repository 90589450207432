import React, { Component } from 'react';
// import { HashRouter, Route, Switch } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>  
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
// const TheEmailApp = React.lazy(() => import('./views/backup/apps/email/TheEmailApp'));

// Pages
const Login = React.lazy(() => import('./views/admin/login/Login'));
const TemPwd = React.lazy(() => import('./views/admin/login/TemPwd'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/error/Page404'));
const Page500 = React.lazy(() => import('./views/error/Page500'));

class App extends Component {

  render() {
    let path = "/admin";

      return (
        <BrowserRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path={`${path}/tempwd`} exact={true} name="Login Page" render={props => <TemPwd {...props}/>} /> 
                <Route exact path={`${path}/login`} name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route path={`${path}/`} name="Main" render={props => <TheLayout {...props}/>} />
                {/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
              </Switch>
            </React.Suspense>
        </BrowserRouter>
      );
    }
}

export default App;
