import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoaderSpinner from 'react-loader-spinner'
import "../scss/react-spinner-loader.css";

import Loader from 'src/utils/loader'

const TheLoader = () => {
  const dispatch = useDispatch();
  const loaderShow = useSelector(state => state.admin.loaderShow);

  useEffect(() => {
    Loader.setLoader(dispatch);
  }, []);

  return (
    <>
      {loaderShow && <>
        <div className='react-spinner-loader-background'></div>        
        <LoaderSpinner type="Bars" color="#00BFFF" height={80} width={80} className='react-spinner-loader' />
      </>
      }
    </>
  )
}

export default React.memo(TheLoader)
